@import '../../projects/theme/src/theme';

body {
  min-width: map_get($grid-breakpoints, lg);
}

/* Hacks */

button {
  // TODO: really unsure it is good idea to apply it on all buttons
  text-transform: uppercase;
}

.btn-link {
  font-weight: bold;
  color: $brand-gray;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: $gray-light-2;
  }
  &.disabled,
  &:disabled {
    color: $gray-light-3 !important;
  }
  &.text-danger:hover {
    color: lighten($danger, 30%) !important;
  }
}

// TODO: Implement secondary buttons properly instead of abusing btn-link class
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  text-decoration: none;
}

/* Global scss */

.dropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 5px;
  button {
    text-transform: none;
  }
}

.dropdown.show .dropdown-toggle:after {
  transform: rotate(180deg);
  transform-origin: center;
}

/* Utils */

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-auto {
  flex: auto;
}

.content-panel {
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 1.5rem;
  border: 1px solid $border-color;
}

.content-panel-main {
  padding: 0;
  background-color: $white;
  margin-top: 1.5rem;
  border: 1px solid $border-color;

  .content-panel-sub {
    padding: 1.5rem;
  }
}
